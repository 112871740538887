import { API, PRODUCT, VERSION } from '@app/config/apiConfig';
import { callApi, msalErrorManager } from '@app/utils/callAPI';
import { MFASessionResponse, MFAChallengeResponse } from '@app/domain/mfa';

const BASE_PATH = `${API}/${PRODUCT.accommodationDocumentation}/${VERSION}/mfa`;

export const apiConfig = {
  createMFASession: {
    uri: `${BASE_PATH}/session`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  mfaChallenge: {
    uri: `${BASE_PATH}/challenge`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  mfaValidate: {
    uri: `${BASE_PATH}/validate`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
};

export const createMFASession = async (body: {
  reason: string;
  scopes: string[];
}): Promise<MFASessionResponse | undefined> =>
  callApi(apiConfig.createMFASession, JSON.stringify(body))
    .then((response) => response as MFASessionResponse)
    .catch(msalErrorManager);

export const mfaChallenge = async (body: { id: string; code: string }): Promise<MFAChallengeResponse | undefined> =>
  callApi(apiConfig.mfaChallenge, JSON.stringify(body))
    .then((response) => response as MFAChallengeResponse)
    .catch(msalErrorManager);

export const mfaValidate = async (body: {
  token: string;
  scopes: string[];
}): Promise<Record<string, string> | undefined> =>
  callApi(apiConfig.mfaValidate, JSON.stringify(body))
    .then((response) => response as Record<string, string>)
    .catch(msalErrorManager);
