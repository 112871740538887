import { API, PRODUCT, VERSION } from '@app/config/apiConfig';
import { callApi, msalErrorManager } from '@app/utils/callAPI';
import {
  Reservation,
  IndexedReservation,
  ReservationList,
  BookReservation,
  ReservationOperation,
} from '@app/domain/reservation';

const BASE_PATH = `${API}/${PRODUCT.accommodationReservation}/${VERSION}/reservation`;

export const apiConfig = {
  list: {
    uri: `${BASE_PATH}?hotel=true&guests=true`,
    options: {
      method: 'GET',
      headers: {},
    },
  },
  get: {
    uri: `${BASE_PATH}/#id?hotel=true&guests=true`,
    options: {
      method: 'GET',
      headers: {},
    },
  },
  create: {
    uri: `${API}/${PRODUCT.accommodationReservation}/${VERSION}/hotel/book`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  update: {
    uri: `${BASE_PATH}/#id`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  delete: {
    uri: `${BASE_PATH}/#id`,
    options: {
      method: 'DELETE',
      headers: {},
    },
  },
  cancel: {
    uri: `${BASE_PATH}/cancel/#id`,
    options: {
      method: 'PUT',
      headers: {},
    },
  },
  checkIn: {
    uri: `${BASE_PATH}/check-in/#id`,
    options: {
      method: 'PUT',
      headers: {},
    },
  },
};

export const listReservations = async (): Promise<ReservationList | undefined> => {
  const config = { ...apiConfig.list };
  return callApi(config)
    .then((response) => response as ReservationList)
    .catch(msalErrorManager);
};

export const getReservation = async (id: string): Promise<IndexedReservation | undefined> => {
  const config = { ...apiConfig.get };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedReservation)
    .catch(msalErrorManager);
};

export const createReservation = async (body: BookReservation): Promise<IndexedReservation | undefined> => {
  const config = { ...apiConfig.create };
  return callApi(config, JSON.stringify(body))
    .then((response) => response as IndexedReservation)
    .catch(msalErrorManager);
};

export const updateReservation = async (id: string, body: Reservation): Promise<IndexedReservation | undefined> => {
  const config = { ...apiConfig.update };
  config.uri = config.uri.replace('#id', id);
  return callApi(config, JSON.stringify(body))
    .then((response) => response as IndexedReservation)
    .catch(msalErrorManager);
};

export const deleteReservation = async (id: string): Promise<ReservationOperation | undefined> => {
  const config = { ...apiConfig.delete };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as ReservationOperation)
    .catch(msalErrorManager);
};

export const cancelReservation = async (id: string): Promise<IndexedReservation | undefined> => {
  const config = { ...apiConfig.cancel };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedReservation)
    .catch(msalErrorManager);
};

export const checkInReservation = async (id: string): Promise<IndexedReservation | undefined> => {
  const config = { ...apiConfig.checkIn };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedReservation)
    .catch(msalErrorManager);
};
