import React, { ReactNode, useEffect, useMemo } from 'react';
import { admin } from '@app/config/authConfig';
import { msalInstance } from '@app/index';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, TimePicker, Button } from 'antd';
import { Rule } from 'antd/lib/form';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { IndexedHotel, Address } from '@app/domain/hotel';
import { updateHotel, createHotel } from '@app/store/slices/hotels';

const { Option } = Select;
interface HotelFormProps {
  hotel?: IndexedHotel | null;
}

const HotelForm: React.FC<HotelFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const email = useMemo(() => {
    return !admin ? msalInstance.getActiveAccount()?.username : undefined;
  }, []);

  useEffect(() => {
    form.setFieldsValue(
      props.hotel
        ? {
            ...props.hotel,
            checkInTime: moment(props.hotel.checkInTime, 'HH:mm'),
            checkOutTime: moment(props.hotel.checkOutTime, 'HH:mm'),
            addressName: props.hotel.address?.name,
            addressCountry: props.hotel.address?.country,
            addressCity: props.hotel.address?.city,
          }
        : {
            email: email ?? '',
            checkInTime: moment('14:00', 'HH:mm'),
            checkOutTime: moment('12:00', 'HH:mm'),
            type: 'hotel',
          },
    );
  }, [form, email, props.hotel]);

  const onFinish = (values: Record<string, unknown>) => {
    const { name, email, type, description, checkInTime, checkOutTime } = values;
    const { addressName, addressCountry, addressCity } = values;
    const address = { name: addressName, country: addressCountry, city: addressCity };
    const hotel = {
      name: name as string,
      email: email as string,
      type: type as string,
      description: description ? (description as string) : '',
      checkInTime: (checkInTime as Moment).format('HH:mm'),
      checkOutTime: (checkOutTime as Moment).format('HH:mm'),
      address: address as Address,
    };
    if (props.hotel) {
      dispatch(updateHotel({ hotelId: props.hotel.id, hotel }));
    } else {
      dispatch(createHotel(hotel));
    }
  };

  const onFinishFailed = (errorInfo: unknown) => console.log('Failed:', errorInfo);

  const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
  const formItem = (label: string | undefined, name: string, rules: Rule[], children: ReactNode, hidden = false) => (
    <Form.Item hidden={hidden} label={label ? t(label) : ''} name={name} rules={rules}>
      {children}
    </Form.Item>
  );

  return (
    <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      {email ? (
        <Form.Item hidden name="email">
          <Input />
        </Form.Item>
      ) : (
        formItem(
          'form.hotel.email',
          'email',
          [{ required: true, type: 'email', message: t('form.hotel.emailError') }],
          <Input />,
        )
      )}
      {formItem(
        'form.hotel.type',
        'type',
        [{ required: true, message: t('form.hotel.typeError') }],
        <Select>
          <Option value="hotel">{t('form.hotel.hotel')}</Option>
        </Select>,
        true,
      )}
      {formItem('form.hotel.name', 'name', [{ required: true, message: t('form.hotel.nameError') }], <Input />)}
      {formItem('form.hotel.description', 'description', [], <Input.TextArea />)}
      {formItem(
        'form.hotel.checkInTime',
        'checkInTime',
        [{ required: true, message: t('form.hotel.checkInTimeError') }],
        <TimePicker format="HH:mm" />,
      )}
      {formItem(
        'form.hotel.checkOutTime',
        'checkOutTime',
        [{ required: true, message: t('form.hotel.checkOutTimeError') }],
        <TimePicker format="HH:mm" />,
      )}
      <Form.Item label={t('form.hotel.address')}>
        {formItem(
          undefined,
          'addressName',
          [{ required: true, message: t('form.hotel.addressNameError') }],
          <Input placeholder={t('form.hotel.addressName')} />,
        )}
        {formItem(
          undefined,
          'addressCountry',
          [{ required: true, message: t('form.hotel.addressCountryError') }],
          <Input placeholder={t('form.hotel.addressCountry')} />,
        )}
        {formItem(
          undefined,
          'addressCity',
          [{ required: true, message: t('form.hotel.addressCityError') }],
          <Input placeholder={t('form.hotel.addressCity')} />,
        )}
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          {props.hotel ? t('form.hotel.update') : t('form.hotel.add')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HotelForm;
