import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistReservations, readReservations, removeReservations } from '@app/services/localStorage.service';
import { ReservationsModel, Reservation, BookReservation } from '@app/domain/reservation';
import { Guest } from '@app/domain/guest';
import * as APIReservation from '@app/api/reservation';
import * as APIGuest from '@app/api/guest';

export interface reservationsState {
  model: ReservationsModel;
  isLoading: boolean;
}

const initialState: reservationsState = {
  model: readReservations(),
  isLoading: false,
};

export const updateReservations = createAsyncThunk('reservations/updateReservations', async (_, { dispatch }) =>
  APIReservation.listReservations()
    .then((reservations) => {
      if (reservations != undefined) {
        dispatch(setReservations(reservations));
      } else {
        dispatch(deleteReservations());
      }
      return reservations ? { reservations } : null;
    })
    .catch((err: unknown) => {
      console.log(err);
      return null;
    }),
);

export const createReservation = createAsyncThunk(
  'reservations/createReservation',
  async (reservation: BookReservation, { dispatch }) => {
    try {
      await APIReservation.createReservation(reservation);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const deleteReservation = createAsyncThunk(
  'reservations/deleteReservation',
  async (reservationId: string, { dispatch }) => {
    try {
      await APIReservation.deleteReservation(reservationId);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const updateReservation = createAsyncThunk(
  'reservations/updateReservation',
  async ({ reservationId, reservation }: { reservationId: string; reservation: Reservation }, { dispatch }) => {
    try {
      await APIReservation.updateReservation(reservationId, reservation);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const cancelReservation = createAsyncThunk(
  'reservations/cancelReservation',
  async (reservationId: string, { dispatch }) => {
    try {
      await APIReservation.cancelReservation(reservationId);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const checkInReservation = createAsyncThunk(
  'reservations/checkInReservation',
  async (reservationId: string, { dispatch }) => {
    try {
      await APIReservation.checkInReservation(reservationId);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const createGuest = createAsyncThunk(
  'reservations/createGuest',
  async (guest: { reservation: string; email: string }, { dispatch }) => {
    try {
      await APIGuest.createGuest(guest);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const deleteGuest = createAsyncThunk('reservations/deleteGuest', async (guestId: string, { dispatch }) => {
  try {
    await APIGuest.deleteGuest(guestId);
    dispatch(updateReservations());
  } catch (err) {
    console.log(err);
  }
});

export const updateGuest = createAsyncThunk(
  'reservations/updateGuest',
  async ({ guestId, guest }: { guestId: string; guest: Guest }, { dispatch }) => {
    try {
      await APIGuest.deleteGuest(guestId);
      await APIGuest.createGuest(guest);
      dispatch(updateReservations());
    } catch (err) {
      console.log(err);
    }
  },
);

export const deleteReservations = createAsyncThunk('reservations/deleteReservations', (_, { dispatch }) => {
  removeReservations();
  dispatch(setReservations(null));
});

export const setReservations = createAction<PrepareAction<ReservationsModel>>(
  'reservations/setReservations',
  (newReservations) => {
    persistReservations(newReservations);

    return {
      payload: newReservations,
    };
  },
);

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateReservations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReservations.fulfilled, (state, action) => {
        state.model = action.payload?.reservations ?? null;
        state.isLoading = false;
      })
      .addCase(createReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReservation.fulfilled, () => {
        /* Do nothing */
      })
      .addCase(deleteReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReservation.fulfilled, () => {
        /* Do nothing */
      })
      .addCase(updateReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReservation.fulfilled, () => {
        /* Do nothing */
      })
      .addCase(cancelReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelReservation.fulfilled, () => {
        /* Do nothing */
      })
      .addCase(checkInReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkInReservation.fulfilled, () => {
        /* Do nothing */
      })
      .addCase(deleteReservations.fulfilled, (state) => {
        state.isLoading = false;
        state.model = null;
      });
  },
});

export default reservationsSlice.reducer;
