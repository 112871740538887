import React from 'react';
import { Col, Row } from 'antd';
import * as S from '../Header.styles';
import logo from 'assets/logo.svg';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <Row justify="space-between" align="middle">
      <S.BurgerCol>
        <a href="/">
          <img src={logo} alt="Neoke" />
        </a>
      </S.BurgerCol>

      <Col>
        <Row align="stretch">
          <Col>
            <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
