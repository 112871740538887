import { API, PRODUCT, VERSION } from '@app/config/apiConfig';
import { PassportVerifiablePresentation } from '@app/domain/PassportVerifiablePresentation';
import { IndexedGuest } from '@app/domain/guest';
import { callApi, msalErrorManager } from '@app/utils/callAPI';

const BASE_PATH = `${API}/${PRODUCT.accommodationDocumentation}/${VERSION}`;

export const apiConfig = {
  getPresentation: {
    uri: `${BASE_PATH}/EntraVerifiedID/readvp`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  confirmDocumentation: {
    uri: `${BASE_PATH}/documentation/confirm/#id`,
    options: {
      method: 'PUT',
      headers: {},
    },
  },
  rejectDocumentation: {
    uri: `${BASE_PATH}/documentation/reject/#id`,
    options: {
      method: 'PUT',
      headers: {},
    },
  },
};

export const getPresentation = async (id: string): Promise<PassportVerifiablePresentation | undefined> => {
  const config = { ...apiConfig.getPresentation };
  return callApi(config, JSON.stringify({ id }))
    .then((response) => response as PassportVerifiablePresentation)
    .catch(msalErrorManager);
};

export const confirmDocumentation = async (id: string): Promise<IndexedGuest | undefined> => {
  const config = { ...apiConfig.confirmDocumentation };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedGuest)
    .catch(msalErrorManager);
};

export const rejectDocumentation = async (id: string): Promise<IndexedGuest | undefined> => {
  const config = { ...apiConfig.rejectDocumentation };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedGuest)
    .catch(msalErrorManager);
};
