import React from 'react';
import { LogoutOutlined, HomeOutlined, ContactsOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'menu.hotels',
    key: 'hotels',
    url: '/hotels',
    icon: <HomeOutlined />,
  },
  {
    title: 'menu.reservations',
    key: 'reservations',
    url: '/reservations',
    icon: <ContactsOutlined />,
  },
  {
    title: 'menu.logout',
    key: 'logout',
    url: '/logout',
    icon: <LogoutOutlined />,
  },
];
