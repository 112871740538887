import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ReservationForm from '@app/components/forms/ReservationForm';
import ReservationTable from '@app/components/tables/ReservationTable';

import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { updateHotels } from '@app/store/slices/hotels';
import { updateReservations } from '@app/store/slices/reservations';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { IndexedReservation } from '@app/domain/reservation';

const ReservationsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loading = useAppSelector((state) => state.reservations.isLoading);
  const hotels = useAppSelector((state) => state.hotels.model);
  const reservations = useAppSelector((state) => state.reservations.model);
  const [displayTodayTable, setDisplayTodayTable] = useState(true);
  const [displayReceivingTable, setDisplayReceivingTable] = useState(true);
  const [displayFutureTable, setDisplayFutureTable] = useState(true);
  const [displayClosedTable, setDisplayClosedTable] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (loading && isModalVisible) setIsModalVisible(false);
  }, [isModalVisible, loading]);

  useEffect(() => (dispatch(updateHotels()), undefined), [dispatch]);
  useEffect(() => (dispatch(updateReservations()), undefined), [dispatch]);

  const _reservations = {
    today: reservations?.data?.filter((r) => r.status?.checkin_available) ?? [],
    receiving:
      reservations?.data?.filter((r) => !r.status?.checkin_available && r.status?.documentation_provision_available) ??
      [],
    future:
      reservations?.data?.filter(
        (r) => r.status?.closed === undefined && !r.status?.documentation_provision_available,
      ) ?? [],
    closed: reservations?.data?.filter((r) => r.status?.closed) ?? [],
  };

  const renderReservationsTable = (
    setter: React.Dispatch<React.SetStateAction<boolean>>,
    value: boolean,
    title: string,
    reservations: Array<IndexedReservation>,
    displayStatus = false,
  ) => {
    return (
      reservations.length > 0 && (
        <div className="reservations-table">
          <div>
            <span>
              {value && <EyeOutlined onClick={() => setter(!value)} />}
              {!value && <EyeInvisibleOutlined onClick={() => setter(!value)} />}
            </span>
            <span className="reservations-table-title">{title}</span>
          </div>
          {value && <ReservationTable reservations={reservations} displayStatus={displayStatus} />}
        </div>
      )
    );
  };

  return (
    <div className="reservations">
      <div style={{ textAlign: 'right', marginBottom: '16px' }}>
        <Button
          type="primary"
          onClick={() => setIsModalVisible(true)}
          loading={loading}
          disabled={!(hotels?.data ?? 0)}
        >
          {t('page.reservations.button.add')}
        </Button>
      </div>
      <h2>{t('list.reservation.header')}</h2>
      <div>
        <Modal
          title={t('form.reservation.add')}
          width={800}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          destroyOnClose={true}
          footer={null}
        >
          <ReservationForm />
        </Modal>
        {reservations?.data?.length === 0 && <ReservationTable reservations={[]} displayStatus={false} />}
        {renderReservationsTable(setDisplayTodayTable, displayTodayTable, 'Check-in today', _reservations.today)}
        {renderReservationsTable(
          setDisplayReceivingTable,
          displayReceivingTable,
          'For which documentation can be provided',
          _reservations.receiving,
        )}
        {renderReservationsTable(
          setDisplayFutureTable,
          displayFutureTable,
          'For which documentation cannot yet be provided',
          _reservations.future,
        )}
        {renderReservationsTable(setDisplayClosedTable, displayClosedTable, 'Old ones', _reservations.closed, true)}
      </div>
    </div>
  );
};

export default ReservationsPage;
