const CURRENT_VERSION = '1';

const requireRefresh = false;

const upgradeToVersion1 = () => {
  console.log('Upgrading to version 1');
  localStorage.setItem('lng', 'en');
  localStorage.setItem('theme', 'light');
  localStorage.setItem('version', '1');
  migration();
};

export const migration = (): void => {
  const version = localStorage.getItem('version') ?? '0';
  switch (version) {
    case '0':
      upgradeToVersion1();
      break;

    case CURRENT_VERSION:
      if (requireRefresh) {
        window.location.reload();
      }
      console.log(`Running version ${CURRENT_VERSION}`);
      break;
  }
};
