import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import hotels from '@app/store/slices/hotels';
import reservations from '@app/store/slices/reservations';

export default {
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  hotels,
  reservations,
};
