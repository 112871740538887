import { API, PRODUCT, VERSION, ADMIN } from '@app/config/apiConfig';
import { callApi, msalErrorManager } from '@app/utils/callAPI';
import { Hotel, HotelList, IndexedHotel, DeleteHotel } from '@app/domain/hotel';

const BASE_PATH = `${API}/${PRODUCT.accommodationOwner}/${VERSION}/hotel`;

export const apiConfig = {
  list: {
    uri: ADMIN
      ? `${API}/${PRODUCT.public}/${VERSION}/hotel`
      : `${API}/${PRODUCT.accommodationOwner}/${VERSION}/my-hotels`,
    options: {
      method: 'GET',
      headers: {},
    },
  },
  get: {
    uri: `${API}/${PRODUCT.public}/${VERSION}/hotel/#id`,
    options: {
      method: 'GET',
      headers: {},
    },
  },
  create: {
    uri: `${BASE_PATH}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  update: {
    uri: `${BASE_PATH}/#id`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  delete: {
    uri: `${BASE_PATH}/#id`,
    options: {
      method: 'DELETE',
      headers: {},
    },
  },
};

export const listHotels = async (): Promise<HotelList | undefined> => {
  const config = { ...apiConfig.list };
  return callApi(config)
    .then((response) => response as HotelList)
    .catch(msalErrorManager);
};

export const getHotel = async (id: string): Promise<IndexedHotel | undefined> => {
  const config = { ...apiConfig.get };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as IndexedHotel)
    .catch(msalErrorManager);
};

export const createHotel = async (body: Hotel): Promise<IndexedHotel | undefined> => {
  const config = { ...apiConfig.create };
  return callApi(config, JSON.stringify(body))
    .then((response) => response as IndexedHotel)
    .catch(msalErrorManager);
};

export const updateHotel = async (id: string, body: Hotel): Promise<IndexedHotel | undefined> => {
  const config = { ...apiConfig.update };
  config.uri = config.uri.replace('#id', id);
  return callApi(config, JSON.stringify(body))
    .then((response) => response as IndexedHotel)
    .catch(msalErrorManager);
};

export const deleteHotel = async (id: string): Promise<DeleteHotel | undefined> => {
  const config = { ...apiConfig.delete };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as DeleteHotel)
    .catch(msalErrorManager);
};
