import { API, PRODUCT, VERSION } from '@app/config/apiConfig';
import { callApi, msalErrorManager } from '@app/utils/callAPI';
import { Guest, GuestOperation } from '@app/domain/guest';

const BASE_PATH = `${API}/${PRODUCT.accommodationReservation}/${VERSION}/guest`;

export const apiConfig = {
  create: {
    uri: `${BASE_PATH}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
  delete: {
    uri: `${BASE_PATH}/#id`,
    options: {
      method: 'DELETE',
      headers: {},
    },
  },
};

export const createGuest = async (body: Guest): Promise<GuestOperation | undefined> => {
  const config = { ...apiConfig.create };
  return callApi(config, JSON.stringify(body))
    .then((response) => response as GuestOperation)
    .catch(msalErrorManager);
};

export const deleteGuest = async (id: string): Promise<GuestOperation | undefined> => {
  const config = { ...apiConfig.delete };
  config.uri = config.uri.replace('#id', id);
  return callApi(config)
    .then((response) => response as GuestOperation)
    .catch(msalErrorManager);
};
