export const Constants = {
  imgBase64Prefix: 'data:image/jpeg;base64,',
  storage: {
    welcome: 'welcome',
    hotels: 'hotels',
    reservations: 'reservations',
    mfa: 'mfa',
  },
  pullInterval: 2000,
  notificationDuration: 2,
  product: {
    extranet: {
      public: 'public',
      accommodationOwner: 'accommodation-owner',
      accommodationReservation: 'accommodation-reservation',
      accommodationDocumentation: 'accommodation-documentation',
    },
    admin: {
      public: 'public',
      accommodationOwner: 'admin',
      accommodationReservation: 'admin',
      accommodationDocumentation: 'admin',
    },
  },
};
