import React from 'react';
import { Modal, Button } from 'antd';
import { PassportVerifiablePresentation } from '@app/domain/PassportVerifiablePresentation';

interface ReviewModalProps {
  visible: boolean;
  onClose: () => void;
  onReject: () => void;
  onConfirm: () => void;
  data: PassportVerifiablePresentation;
  enableActions: boolean;
}

const ReviewModal: React.FC<ReviewModalProps> = ({ visible, onClose, onConfirm, onReject, data, enableActions }) => {
  if (!data) return null;

  const { claims } = data.credentials[0];

  const displayImage = (base64: string) => {
    return <img src={`data:image/jpeg;base64,${base64}`} alt="Document" style={{ width: '100%' }} />;
  };

  return (
    <Modal
      title="Review Guest Information"
      open={visible}
      onCancel={onClose}
      width={500}
      destroyOnClose
      footer={[
        <Button key="close" type="dashed" onClick={onClose}>
          Close
        </Button>,
        <Button key="back" type="dashed" onClick={onReject}>
          Reject
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Accept
        </Button>,
      ].filter((_, index) => enableActions || index === 0)}
    >
      <div>
        <h3>First Name:</h3>
        <p>{claims.firstName}</p>
        <h3>Last Name:</h3>
        <p>{claims.lastName}</p>
        <h3>Date of Birth:</h3>
        <p>{claims.dateOfBirth}</p>
        <h3>Passport Number:</h3>
        <p>{claims.passportNumber}</p>
        <h3>Passport Country:</h3>
        <p>{claims.passportCountry}</p>
        <h3>Passport Expiry Date:</h3>
        <p>{claims.passportExpiryDate}</p>
        <h3>Selfie:</h3>
        {displayImage(claims.selfie)}
        <h3>Passport:</h3>
        {displayImage([claims.passport01, claims.passport02, claims.passport03].join(''))}
      </div>
    </Modal>
  );
};

export default ReviewModal;
