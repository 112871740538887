import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-dropdown {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      max-width: 323px;
    }
  }

  a {
    color: var(--primary-color);
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 2rem;
    min-height: 6rem;
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.25rem;
      right: 1.25rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }
      .ant-notification-notice-with-icon .ant-notification-notice-description  {
        margin-top: 0.625rem;
      }
    }
    
    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
  }
  
    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }
  
    &.ant-notification-notice-success {
      border: 1px solid var(--success-color);
      background: var(--notification-success-color);
      
      .title {
        color: var(--success-color);
      }
    }
  
    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);
  
      .title {
        color: var(--primary-color);
      }
    }
  
    &.ant-notification-notice-warning {
      border: 1px solid var(--warning-color);
      background: var(--notification-warning-color);
  
      .title {
        color: var(--warning-color);
      }
    }
  
    &.ant-notification-notice-error {
      border: 1px solid var(--error-color);
      background: var(--notification-error-color);
  
      .title {
        color: var(--error-color);
      }
    }
  
    .success-icon {
      color: var(--success-color);
    }
  
    .info-icon {
      color: var(--primary-color);
    }
  
    .warning-icon {
      color: var(--warning-color);
    }
  
    .error-icon {
      color: var(--error-color);
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }
  // notifications end

  // Neoke start
  .only-mobile .header {
    padding-top: 14px;
    width: 100%;
  }

  .only-mobile .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #131314;
    margin: 14px;
    margin-top: 90px;
  }

  .only-mobile .header img {
    margin-left: auto;
    margin-right: auto;
  }

  .only-mobile h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    margin: 0px;
    margin-bottom: 4px;
  }

  .only-mobile h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    margin-bottom: 8px;
  }

  .only-mobile .qr {
    margin: 30px;
  }

  .only-mobile p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6F6D73;
    margin: 0px;
    margin-top: 8px;
  }

  .notification-veriff-wait .ant-notification-notice-message,
  .notification-mfa .ant-notification-notice-message {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .help, .account {
    padding: 8px 0px;
    color: #131314;
  }

  .help h1, .account h1, {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }

  .welcome-title {
    font-size: 20px;
  }

  .account-block {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .account-block .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .account-block .options {
    margin-top: 12px;
    padding: 0px;
    gap: 12px;
    border: 1px solid #d2d0d6;
    border-radius: 8px;
  }

  .account-block .options .option {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .account-block .options ul {
    list-style: none;
    margin-bottom: 0px;
  }

  .account-block .options ul li {
    padding: 12px;
    border-bottom: 1px solid #d2d0d6;
  }

  .account-block .options ul li a {
    color: #131314;
  }

  .account-block .options ul li:last-of-type {
    border-bottom: 0px;
  }

  .help p, .help li, .welcome-body p, .welcome-body li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .help p, .welcome-body p {
    margin-bottom: 0px;
  }

  .help .section, .welcome-body .section {
    margin-bottom: 24px;
  }

  .help li, .welcome-body li {
    margin-left: 24px;
  }

  .ant-modal-body .subtitle {
    margin-bottom: 24px;
  }

  button.ant-modal-close {
    padding: 0px !important;
  }

  .ant-layout-content {
    padding-top: 0px;
  }

  .page-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  button {
    border-radius: 100px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    padding: 10px 20px !important;
    gap: 8px !important;
  }

  .obtain-credentials-container {
    border: 1px solid #d2d0d6;
    border-radius: 12px;
    align-self: stretch;
    gap: 8px;
    padding: 27px 16px 24px 16px;
  }

  .obtain-credentials-container .info {
    text-align: center;
    width: 100%;
    padding-bottom: 16px;
  }

  .obtain-credentials-container .info .anticon svg {
    font-size: 25px;
    color: #430bab;
  }

  .obtain-credentials-container .info p {
    font-weight 500px;
    font-size: 18px;
    line-height: 24px;
    padding-top: 10px;
    margin-bottom: 0px;
  }

  .obtain-credentials-container .processors button {
    margin-bottom: 16px;
  }

  .obtain-credentials-container .processors button:last-of-type {
    margin-bottom: 0px;
  }

  .credential-content, .reservation-content {
    border: 1px solid #D2D0D6;
    border-radius: 8px;
    padding: 8px 0px;
    margin-bottom: 10px;
  }

  .credential-field, .reservation-field {
    border-bottom: 1px solid #D2D0D6;
    padding: 0px 16px;
  }

  .credential-field:last-of-type, .reservation-field:last-of-type {
    border-bottom: 0px;
  }

  .credential-field-title, .reservation-field-title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6F6D73;
    padding-top: 8px;
  }

  .credential-field-content, .reservation-field-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #131314;
    padding-bottom: 8px;
  }

  .credential-field-content img {
    width: 100%;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .credential-content .buttons, .reservation-content .buttons {
    margin-bottom: -8px;
  }

  .vp-modal .ant-modal-footer {
    position: sticky;
    bottom: 0;
    background: #ffffff;
    width: 600px;
    max-width: calc(100vw - 32px);
    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      max-width: calc(100vw - 16px);
    }
  }

  .reservation-dcouments {
    z-index: -10;
  }

  .reservation-documents-radio-group {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 24px;
  }

  .reservation-documents-radio-group label {
    width: 50%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    height: 44px;
    line-height: 44px;
  }

  .reservation-documents-container .empty {
    padding: 24px 16px;
    text-align: center;
  }

  .reservation-documents-container .empty p {
    font-style: normal;
    height: 24px;
    padding-top: 11px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6f6d73;
  }

  .reservation-documents-container .empty > .anticon svg {
    color: #430bab;
    font-size: 25px;
  }

  .reservation-documents-container .empty ol {
    text-align: left;
    padding-left: 60px;
  }

  .reservation-documents-container .empty li {
    padding-left: 15px;
  }

  .reservation-documents-no-passport button {
    background: #fcecdb;
    border-color: #fcce85;
    border-radius: 8px !important;
    margin-bottom: 20px;
    color: #131314;
    text-align: left;
  }

  .reservation-documents-no-passport button .button-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reservation-documents-no-passport button.ant-btn-loading .button-text {
    display: none;
  }

  .no-credential {
    color: red;
  }

  .no-passport {
    border: 1px solid #d2d0d6;
    border-radius: 12px;
    padding: 16px;
  }

  .no-passport .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #131314;
    padding-bottom: 4px;
    padding-top: 10px;
  }

  .no-passport .body {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6f6d73;
    padding-bottom: 16px;
  }

  .no-passport .body p {
    margin-bottom: 0px;
  }

  .no-passport button {
    height: 40px;
  }

  .cover {
    height: 200px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 12px;
    position: relative;
  }

  .cover:last-of-type {
    margin-bottom: 0px;
  }

  .cover .status {
    position: absolute;
    top: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
    width: 100%;
    margin-left: -16px;
    border-radius: 8px 8px 0px 0px;
    background: rgb(255, 255, 255, 0.1);
  }

  .cover .content {
    position: absolute;
    bottom: 0;
    padding-bottom: 16px;
  }

  .cover .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    padding-top: 12px;
  }

  .cover .subtitle {
    padding-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .cover .icon {
    width: 27px;
  }

  .content-cover-close {
    color: #131314;
    padding-bottom: 24px;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
  }

  .content-cover-close .details {
    color: #131314;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
  }

  .ant-popover-buttons button {
    height: 40px;
  }

  .access-code-modal {
    padding: 0px;
    margin: 0px;
  }

  .access-code-modal .qr {
    padding: 20px;
  }

  .access-code-modal .passphrase {
    padding: 16px;
    width: 100%;
    text-align: center;
  }

  .access-code-modal .passphrase .title {
    padding-bottom: 10px;
    color: #131314;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .access-code-modal .passphrase .words {
    color: #131314;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-table-row-expand-icon {
    padding: 0px !important;
  }

  .ant-table {
    font-size: 13px !important;
  }

  tbody {
    vertical-align: top !important;
  }

  .ant-table-thead {
    line-height: 0px !important;
  }

  .reservations-table {
    padding-bottom: 25px;
  }
  .reservations-table-title {
    margin-left: 8px;
  }
  // Neoke end
`;
