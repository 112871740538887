import { Constants } from '@app/utils/constants';
import { admin } from '@app/config/authConfig';

export const API = process.env.REACT_APP_API;
export const VERSION = process.env.REACT_APP_API_VERSION;
export const PRODUCT = admin ? Constants.product.admin : Constants.product.extranet;
export const ADMIN = admin;

export interface CallAPIConfigOptions {
  method: string;
  headers: Record<string, unknown>;
}

export interface CallApiConfig {
  uri: string;
  options: CallAPIConfigOptions;
}
