import React, { ReactNode, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, DatePicker, Button, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { IndexedReservation } from '@app/domain/reservation';
import { updateReservation, createReservation } from '@app/store/slices/reservations';

const { RangePicker } = DatePicker;

interface ReservationFormProps {
  reservation?: IndexedReservation | null;
}

const ReservationForm: React.FC<ReservationFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const hotels = useAppSelector((state) => state.hotels.model);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(
      props.reservation
        ? {
            ...props.reservation,
            dates: [
              moment(props.reservation.checkInDay, 'YYYY-MM-DD'),
              moment(props.reservation.checkOutDay, 'YYYY-MM-DD'),
            ],
          }
        : {
            numberOfGuests: 1,
          },
    );
  }, [form, props.reservation]);

  const onFinish = (values: Record<string, unknown>) => {
    const { hotelId, email, firstName, lastName, numberOfGuests, dates } = values;
    const date = dates as Array<Moment>;
    const reservation = {
      email: email as string,
      firstName: firstName as string,
      lastName: lastName as string,
      numberOfGuests: numberOfGuests as number,
      checkInDay: date[0].format('YYYY-MM-DD'),
      checkOutDay: date[1].format('YYYY-MM-DD'),
    };
    if (props.reservation) {
      dispatch(
        updateReservation({
          reservationId: props.reservation.id,
          reservation: { ...reservation, hotelId: hotelId as string },
        }),
      );
    } else {
      dispatch(
        createReservation({
          hotel: hotelId as string,
          reservation,
          guests: [email as string],
        }),
      );
    }
  };

  const onFinishFailed = (errorInfo: unknown) => console.log('Failed:', errorInfo);

  const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };
  const formItem = (label: string | undefined, name: string, rules: Rule[], children: ReactNode, hidden = false) => (
    <Form.Item hidden={hidden} label={label ? t(label) : ''} name={name} rules={rules}>
      {children}
    </Form.Item>
  );

  return (
    <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      {formItem(
        'form.reservation.hotel',
        'hotelId',
        [{ required: true, message: t('form.reservation.hotelError') }],
        <Select options={hotels?.data.map((hotel) => ({ value: hotel.id, label: hotel.name }))} />,
      )}
      {formItem(
        'form.reservation.email',
        'email',
        [{ required: true, type: 'email', message: t('form.reservation.emailError') }],
        <Input />,
      )}
      {formItem(
        'form.reservation.firstName',
        'firstName',
        [{ required: true, message: t('form.reservation.firstNameError') }],
        <Input />,
      )}
      {formItem(
        'form.reservation.lastName',
        'lastName',
        [{ required: true, message: t('form.reservation.lastNameError') }],
        <Input />,
      )}
      {formItem(
        'form.reservation.dates',
        'dates',
        [{ required: true, message: t('form.reservation.datesError') }],
        <RangePicker />,
      )}
      {formItem(
        'form.reservation.numberOfGuests',
        'numberOfGuests',
        [{ required: true, message: t('form.reservation.numberOfGuestsError') }],
        <InputNumber min={1} />,
      )}
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          {props.reservation ? t('form.reservation.update') : t('form.reservation.add')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReservationForm;
