import { HotelsModel } from '@app/domain/hotel';
import { ReservationsModel } from '@app/domain/reservation';
import { Constants } from '@app/utils/constants';

const { hotels, reservations } = Constants.storage;

const persist = (v: unknown, id: string): void => localStorage.setItem(id, JSON.stringify(v));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const read = (id: string): any => {
  const str = localStorage.getItem(id);
  return str && JSON.parse(str);
};

const remove = (id: string): void => localStorage.removeItem(id);

// Hotels
export const persistHotels = (v: HotelsModel): void => persist(v, hotels);
export const readHotels = (): HotelsModel => read(hotels);
export const removeHotels = (): void => remove(hotels);

// Reservations
export const persistReservations = (v: ReservationsModel): void => persist(v, reservations);
export const readReservations = (): ReservationsModel => read(reservations);
export const removeReservations = (): void => remove(reservations);
