import React, { useState, useEffect, useCallback } from 'react';
import { Table, Modal, Tooltip, Input, notification, Skeleton } from 'antd';
import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  StopOutlined,
  FileSearchOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IndexedReservation } from '@app/domain/reservation';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import {
  deleteReservation,
  createGuest,
  deleteGuest,
  cancelReservation,
  checkInReservation,
  updateReservations,
} from '@app/store/slices/reservations';
import { createMFASession, mfaChallenge, mfaValidate } from '@app/api/mfa';
import { MFASessionResponse } from '@app/domain/mfa';
import { Hotel } from '@app/domain/hotel';
import { IndexedGuest } from '@app/domain/guest';
import { hash } from '@app/utils/utils';
import ReservationForm from '@app/components/forms/ReservationForm';
import ReviewModal from '@app/components/modals/ReviewModal';
import { getPresentation, confirmDocumentation, rejectDocumentation } from '@app/api/credential';
import { PassportVerifiablePresentation } from '@app/domain/PassportVerifiablePresentation';
import { Constants } from '@app/utils/constants';
import { admin } from '@app/config/authConfig';
import { formatReservationDates } from '@app/utils/dates';

const ReservationTable: React.FC<{ reservations: Array<IndexedReservation>; displayStatus: boolean }> = ({
  reservations,
  displayStatus = true,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loading = useAppSelector((state) => state.reservations.isLoading);
  const [selected, setSelected] = useState<IndexedReservation | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [guestEmail, setGuestEmail] = useState('');
  const [guestId, setGuestId] = useState<string | undefined>(undefined);
  const [displayActions, setDisplayActions] = useState<Record<string, boolean>>({});
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [reviewData, setReviewData] = useState<PassportVerifiablePresentation | undefined>(undefined);
  const [guestRecord, setGuestRecord] = useState<IndexedGuest | undefined>(undefined);
  const [validMFA, setValidMFA] = useState<boolean>(false);
  const [isMFAModalVisible, setIsMFAModalVisible] = useState<boolean>(false);
  const [mfaSession, setMFASession] = useState<MFASessionResponse | undefined>(undefined);
  const [mfaSessionCode, setMFASessionCode] = useState<string>('');
  const [mfaChallengeRunning, setMFAChallengeRunning] = useState<boolean>(false);
  const [mfaRemainingUses, setMFARemainingUses] = useState<number | undefined>(undefined);
  const [enableReviewActions, setEnableReviewActions] = useState<boolean>(false);

  const unknownErrorNotification = useCallback(() => {
    notification.open({
      className: 'notification-error',
      message: t('notification.error.unknown'),
      icon: null,
      duration: Constants.notificationDuration,
    });
  }, [t]);

  const isEmailValidAndUnique = (email: string, reservation: IndexedReservation) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !reservation.guests.some((guest) => guest.email === email);

  const isCanceled = (reservation: IndexedReservation) => reservation.status?.closed === 'canceled';

  const isCheckedIn = (reservation: IndexedReservation) => reservation.status?.closed === 'checked-in';

  const checkInAvailable = (reservation: IndexedReservation) => reservation.status?.checkin_available;

  useEffect(() => {
    if (loading && isModalVisible) setIsModalVisible(false);
  }, [isModalVisible, loading]);

  useEffect(() => setIsModalVisible(!!selected), [selected]);

  useEffect(() => {
    if ((admin || (!admin && validMFA)) && guestRecord) {
      getPresentation(guestRecord.id)
        .then((guestData) => {
          setReviewData(guestData);
          setGuestId(guestRecord.id);
          setReviewModalVisible(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [validMFA, guestRecord]);

  const iconStyle = {
    fontSize: '14px',
    margin: '4px',
    cursor: 'pointer',
  };

  const columns = [
    {
      title: 'Dates',
      key: 'dates',
      render: (_: unknown, record: IndexedReservation) => {
        const dates = formatReservationDates(new Date(record.checkInDay), new Date(record.checkOutDay));
        return (
          <>
            <div>{dates[0]}</div>
            <div>{dates[1]}</div>
          </>
        );
      },
    },
    { title: 'Hotel', dataIndex: 'hotel', key: 'hotel', render: (hotel: Hotel) => hotel.name },
    {
      title: 'Main Guest',
      key: 'mainGuest',
      render: (_: string, record: IndexedReservation) => (
        <>
          <div>
            {record.lastName}, {record.firstName.charAt(0)}
          </div>
        </>
      ),
    },
    {
      title: 'Guests',
      key: 'numberOfGuests',
      render: (_: unknown, record: IndexedReservation) => (
        <>
          <div>Total: {record.numberOfGuests}</div>
          <div>Reached: {record.guests.length}</div>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: unknown, record: IndexedReservation) => {
        let status = '';
        if (record.status?.closed) {
          status = t(`model.reservation.state.${record.status.closed}`);
        } else {
          status = record.status?.documentation_provision_available
            ? t('model.reservation.state.receivingDocumentation')
            : t('model.reservation.state.awaitingPrecheckin');
        }

        return <div>{status}</div>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: IndexedReservation) => (
        <>
          {displayActions[record.id] === undefined || displayActions[record.id] ? (
            <>
              {checkInAvailable(record) && (
                <div>
                  <Tooltip title="Checkin">
                    <CheckSquareOutlined style={iconStyle} onClick={() => dispatch(checkInReservation(record.id))} />
                  </Tooltip>
                  <span>Check in</span>
                </div>
              )}
              {!isCanceled(record) && !isCheckedIn(record) && record.numberOfGuests > record.guests.length && (
                <div>
                  <Tooltip title="Add guest">
                    <UserAddOutlined
                      style={iconStyle}
                      onClick={() => setDisplayActions((prev) => ({ ...prev, [record.id]: false }))}
                    />
                  </Tooltip>
                  Add guest
                </div>
              )}
              {!isCanceled(record) && !isCheckedIn(record) && (
                <div>
                  <Tooltip title="Edit">
                    <EditOutlined style={iconStyle} onClick={() => setSelected(record)} />
                  </Tooltip>
                  Edit
                </div>
              )}
              {!isCanceled(record) && !isCheckedIn(record) && (
                <div>
                  <Tooltip title="Cancel">
                    <StopOutlined style={iconStyle} onClick={() => dispatch(cancelReservation(record.id))} />
                  </Tooltip>
                  Cancel
                </div>
              )}
              <div>
                <Tooltip title="Delete">
                  <DeleteOutlined style={iconStyle} onClick={() => dispatch(deleteReservation(record.id))} />
                  Delete
                </Tooltip>
              </div>
            </>
          ) : (
            <Input
              size="small"
              placeholder="Email"
              type="email"
              value={guestEmail}
              onChange={(e) => setGuestEmail(e.target.value)}
              addonBefore={
                <CloseOutlined
                  onClick={() => {
                    setGuestEmail('');
                    setDisplayActions((prev) => ({ ...prev, [record.id]: true }));
                  }}
                />
              }
              addonAfter={
                isEmailValidAndUnique(guestEmail, record) ? (
                  <UserAddOutlined
                    onClick={() => {
                      dispatch(createGuest({ email: guestEmail, reservation: record.id }));
                      setGuestEmail('');
                      setDisplayActions((prev) => ({ ...prev, [record.id]: true }));
                    }}
                  />
                ) : null
              }
            />
          )}
        </>
      ),
    },
  ].filter((column) => displayStatus || column.key !== 'status');

  const guestColumns = [
    {
      title: 'Guest',
      dataIndex: 'email',
      key: 'email',
      render: (_: unknown, record: IndexedGuest & { reservation: IndexedReservation }) => (
        <>
          <div>
            {record.email}
            {record.email === record.reservation.email && ` (main guest)`}
          </div>
        </>
      ),
    },
    {
      title: 'Documentation status',
      key: 'status',
      render: (_: unknown, record: IndexedGuest & { reservation: IndexedReservation }) => {
        let output;
        if (record.reservation.status?.closed) {
          output = record.documentation
            ? t('model.reservation.state.wasProvided')
            : t('model.reservation.state.wasNotProvided');
        } else if (!record.reservation.status?.documentation_provision_available) {
          output = t('model.reservation.state.notNeededYet');
        } else {
          output = t(`model.reservation.state.${record.documentation}`);
        }
        return <div>{output}</div>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: IndexedGuest & { reservation: IndexedReservation }) => (
        <>
          <div>
            {record.email !== record.reservation.email && (
              <>
                <Tooltip title="Delete">
                  <DeleteOutlined style={iconStyle} onClick={() => dispatch(deleteGuest(record.id))} />
                </Tooltip>
                Delete
              </>
            )}
          </div>
          <div>
            {['provided', 'validated', 'rejected'].indexOf(record?.documentation ?? '') !== -1 && (
              <>
                <Tooltip title="Review">
                  <FileSearchOutlined
                    style={iconStyle}
                    onClick={() => {
                      if (admin) {
                        setGuestRecord(record);
                      } else {
                        setEnableReviewActions(record?.documentation === 'provided');
                        setValidMFA(false);
                        setGuestRecord(record);
                        onButtonClick();
                      }
                    }}
                  />
                </Tooltip>
                Review
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  const onButtonClick = () => {
    setMFASession(undefined);
    setMFASessionCode('');
    setMFARemainingUses(undefined);
    setMFAChallengeRunning(false);
    const mfa = sessionStorage.getItem(Constants.storage.mfa);
    if (!mfa) {
      setIsMFAModalVisible(true);
      createMFASession({ reason: t('mfa.reason.presentation'), scopes: ['vp.read'] })
        .then((response) => {
          if (response) {
            setMFASession(response);
          } else {
            setIsMFAModalVisible(false);
            unknownErrorNotification();
          }
        })
        .catch(() => {
          setIsMFAModalVisible(false);
          unknownErrorNotification();
        });
    } else {
      mfaValidate({ token: mfa, scopes: ['vp.read'] })
        .then((response) => {
          if (response) {
            if (response?.result === 'ok') {
              setValidMFA(true);
            } else {
              sessionStorage.removeItem(Constants.storage.mfa);
              onButtonClick();
            }
          } else {
            unknownErrorNotification();
          }
        })
        .catch(() => {
          unknownErrorNotification();
        });
    }
  };

  const renderMFAModal = () => (
    <Modal
      destroyOnClose={true}
      title={t('modal.mfa.title')}
      open={isMFAModalVisible}
      onOk={() => {
        setMFAChallengeRunning(true);
        mfaChallenge({ id: mfaSession?.id ?? '', code: mfaSessionCode })
          .then((response) => {
            setMFAChallengeRunning(false);
            if (response) {
              if (response.uses) {
                setMFARemainingUses(response.uses as number);
              } else if (!response.token) {
                notification.open({
                  className: 'notification-mfa',
                  message: t('notification.mfa.maxTries'),
                  icon: null,
                  duration: Constants.notificationDuration,
                });
                setIsMFAModalVisible(false);
              } else {
                sessionStorage.setItem(Constants.storage.mfa, response.token as string);
                setIsMFAModalVisible(false);
                onButtonClick();
              }
            } else {
              unknownErrorNotification();
            }
          })
          .catch(() => {
            setMFAChallengeRunning(false);
            unknownErrorNotification();
          });
      }}
      onCancel={() => {
        setIsMFAModalVisible(false);
      }}
      okButtonProps={{
        disabled: mfaSessionCode.length == 0,
        loading: mfaChallengeRunning,
      }}
    >
      {mfaSession && (
        <>
          <p>{t('modal.mfa.description')}</p>
          <Input onChange={(e) => setMFASessionCode(e.target.value)} />
          {mfaRemainingUses !== undefined && mfaRemainingUses != 3 && (
            <p>{t('modal.mfa.remaining_uses', { uses: mfaRemainingUses })}</p>
          )}
        </>
      )}
      {!mfaSession && <Skeleton />}
    </Modal>
  );

  return (
    <>
      <Table
        key={hash(JSON.stringify(reservations))}
        dataSource={reservations}
        columns={columns}
        loading={loading}
        rowKey="id"
        size="small"
        pagination={false}
        expandable={{
          expandedRowRender: (record: IndexedReservation) => {
            const guests = record.guests.map((guest) => ({
              ...guest,
              reservation: record,
            }));
            return <Table size="small" dataSource={guests} columns={guestColumns} rowKey="id" pagination={false} />;
          },
        }}
      />
      <Modal
        title={t('form.reservation.update')}
        width={800}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <ReservationForm reservation={selected} />
      </Modal>
      {renderMFAModal()}
      {reviewModalVisible && reviewData && guestId && (
        <ReviewModal
          visible={reviewModalVisible}
          onClose={() => {
            setGuestRecord(undefined);
            setReviewModalVisible(false);
            setReviewData(undefined);
            setGuestId(undefined);
          }}
          data={reviewData}
          enableActions={enableReviewActions}
          onConfirm={() => {
            confirmDocumentation(guestId)
              .then((r) => {
                if (r) dispatch(updateReservations());
                setGuestRecord(undefined);
                setReviewModalVisible(false);
                setReviewData(undefined);
                setGuestId(undefined);
              })
              .catch((err) => {
                console.log(err);
                setGuestRecord(undefined);
                setReviewModalVisible(false);
                setReviewData(undefined);
                setGuestId(undefined);
              });
          }}
          onReject={() => {
            rejectDocumentation(guestId)
              .then((r) => {
                if (r) dispatch(updateReservations());
                setGuestRecord(undefined);
                setReviewModalVisible(false);
                setReviewData(undefined);
                setGuestId(undefined);
              })
              .catch((err) => {
                console.log(err);
                setGuestRecord(undefined);
                setReviewModalVisible(false);
                setReviewData(undefined);
                setGuestId(undefined);
              });
          }}
        />
      )}
    </>
  );
};

export default ReservationTable;
