import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistHotels, readHotels, removeHotels } from '@app/services/localStorage.service';
import { HotelsModel, Hotel } from '@app/domain/hotel';
import * as API from '@app/api/hotel';

export interface hotelsState {
  model: HotelsModel;
  isLoading: boolean;
}

const initialState: hotelsState = {
  model: readHotels(),
  isLoading: false,
};

export const updateHotels = createAsyncThunk('hotels/updateHotels', async (_, { dispatch }) =>
  API.listHotels()
    .then((hotels) => {
      if (hotels != undefined) {
        dispatch(setHotels(hotels));
      } else {
        dispatch(deleteHotels());
      }
      return hotels ? { hotels } : null;
    })
    .catch((err: unknown) => {
      console.log(err);
      return null;
    }),
);

export const createHotel = createAsyncThunk('hotels/createHotel', async (hotel: Hotel, { dispatch }) => {
  try {
    await API.createHotel(hotel);
    dispatch(updateHotels());
  } catch (err) {
    console.log(err);
  }
});

export const deleteHotel = createAsyncThunk('hotels/deleteHotel', async (hotelId: string, { dispatch }) => {
  try {
    await API.deleteHotel(hotelId);
    dispatch(updateHotels());
  } catch (err) {
    console.log(err);
  }
});

export const updateHotel = createAsyncThunk(
  'hotels/updateHotel',
  async ({ hotelId, hotel }: { hotelId: string; hotel: Hotel }, { dispatch }) => {
    try {
      await API.updateHotel(hotelId, hotel);
      dispatch(updateHotels());
    } catch (err) {
      console.log(err);
    }
  },
);

export const deleteHotels = createAsyncThunk('hotels/deleteHotels', (_, { dispatch }) => {
  removeHotels();
  dispatch(setHotels(null));
});

export const setHotels = createAction<PrepareAction<HotelsModel>>('hotels/setHotels', (newHotels) => {
  persistHotels(newHotels);

  return {
    payload: newHotels,
  };
});

export const hotelsSlice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHotels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHotels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.model = action.payload?.hotels ?? null;
      })
      .addCase(createHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHotel.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteHotel.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHotel.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteHotels.fulfilled, (state) => {
        state.isLoading = false;
        state.model = null;
      });
  },
});

export default hotelsSlice.reducer;
