import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import HotelForm from '@app/components/forms/HotelForm';
import HotelTable from '@app/components/tables/HotelTable';

import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { updateHotels } from '@app/store/slices/hotels';

const HotelsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loading = useAppSelector((state) => state.hotels.isLoading);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (loading && isModalVisible) setIsModalVisible(false);
  }, [isModalVisible, loading]);

  useEffect(() => (dispatch(updateHotels()), undefined), [dispatch]);

  return (
    <div className="hotels">
      <div style={{ textAlign: 'right', marginBottom: '16px' }}>
        <Button type="primary" onClick={() => setIsModalVisible(true)} loading={loading}>
          {t('page.hotels.button.add')}
        </Button>
      </div>
      <h2>{t('list.hotel.header')}</h2>
      <div>
        <Modal
          title={t('form.hotel.add')}
          width={800}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          destroyOnClose={true}
          footer={null}
        >
          <HotelForm />
        </Modal>
        <HotelTable />
      </div>
    </div>
  );
};

export default HotelsPage;
