export const formatReservationDates = (checkInDate: Date, checkOutDate: Date): string[] => {
  const currentYear = new Date().getFullYear();
  const checkInYear = checkInDate.getFullYear();
  const checkOutYear = checkOutDate.getFullYear();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const checkInDayName = dayNames[checkInDate.getDay()];
  const checkOutDayName = dayNames[checkOutDate.getDay()];
  const checkInMonthName = monthNames[checkInDate.getMonth()];
  const checkOutMonthName = monthNames[checkOutDate.getMonth()];
  const checkInDay = checkInDate.getDate();
  const checkOutDay = checkOutDate.getDate();
  const checkInYearString = checkInYear !== currentYear || checkInYear !== checkOutYear ? `, ${checkInYear}` : '';
  const checkOutYearString = checkOutYear !== currentYear || checkOutYear !== checkInYear ? `, ${checkOutYear}` : '';
  const checkInDateString = `${checkInDayName}, ${checkInDay} ${checkInMonthName}${checkInYearString}`;
  const checkOutDateString = `${checkOutDayName}, ${checkOutDay} ${checkOutMonthName}${checkOutYearString}`;
  return [checkInDateString, checkOutDateString];
};

export const formatPassportDate = (date: string): string => {
  const [year, month, day] = date.split('-');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${parseInt(day, 10)} ${monthName}, ${year}`;
};
