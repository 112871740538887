import { LogLevel } from '@azure/msal-browser';

export const admin = `https://${window.location.hostname}` === process.env.REACT_APP_B2C_REDIRECT_URI_ADMIN;

export const b2cPolicies = admin
  ? {
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_B2C_TENANT_ID}`,
    }
  : {
      names: {
        signUpSignIn: process.env.REACT_APP_B2C_FLOW_NAME || '',
      },
      authorities: {
        signUpSignIn: {
          authority: process.env.REACT_APP_B2C_AUTHORITY || '',
        },
      },
      authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN || '',
    };

const auth = admin
  ? {
      clientId: process.env.REACT_APP_B2C_CLIENT_ID_ADMIN || '',
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_B2C_TENANT_ID}`,
      redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI_ADMIN || '',
      postLogoutRedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI_ADMIN || '',
      navigateToLoginRequestUrl: false,
    }
  : {
      clientId: process.env.REACT_APP_B2C_CLIENT_ID || '',
      authority: process.env.REACT_APP_B2C_AUTHORITY || '',
      knownAuthorities: [process.env.REACT_APP_B2C_AUTHORITY_DOMAIN || ''],
      redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI || '',
      postLogoutRedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI || '',
      navigateToLoginRequestUrl: false,
    };

export const msalConfig = {
  auth,
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const scopes = admin
  ? [
      ...['hotel.read', 'hotel.write'].map(
        (s) => `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/api-neoke/${s}`,
      ),
    ]
  : [
      'openid',
      'profile',
      'offline_access',
      ...['hotel.read', 'hotel.write'].map(
        (s) => `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/api-neoke/${s}`,
      ),
    ];

export const loginRequest = {
  scopes,
};

export const silentRequest = {
  scopes,
  loginHint: 'example@domain.net',
};
