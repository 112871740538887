import { CallApiConfig } from '@app/config/apiConfig';
import { loginRequest } from '@app/config/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '../index';
import { Constants } from './constants';

export async function callApi(config: CallApiConfig, body?: string, accessToken?: string): Promise<unknown> {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const mfa = sessionStorage.getItem(Constants.storage.mfa);

  headers.append('Authorization', bearer);
  if (mfa) headers.append('MFA', mfa);

  Object.keys(config.options.headers).forEach(function (key) {
    headers.append(key, config.options.headers[key] as string);
  });

  const options = {
    method: config.options.method,
    body,
    headers,
  };

  return fetch(config.uri, options).then((response) => response.json());
}

export function msalErrorManager(e: unknown): undefined {
  if (e instanceof InteractionRequiredAuthError) {
    msalInstance.acquireTokenRedirect({
      ...loginRequest,
      account: msalInstance.getActiveAccount() || undefined,
    });
  } else {
    throw e;
  }
  return undefined;
}
