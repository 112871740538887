import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { useMSAL } from '@app/hooks/useMSAL';
import { loginRequest } from '@app/config/authConfig';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import HotelsPage from '@app/pages/HotelsPage';
import ReservationsPage from '@app/pages/ReservationsPage';
import { useAppSelector } from '@app/hooks/reduxHooks';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));

const Logout = React.lazy(() => import('./Logout'));

export const DATA_PATH = '/';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const hotels = useAppSelector((state) => state?.hotels?.model?.data);
  const Login = () => {
    const { instance } = useMSAL();
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
      }
    }, [instance, inProgress, isAuthenticated]);

    return <></>;
  };

  return (
    <>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path={DATA_PATH} element={<MainLayout />}>
              <Route index element={hotels?.length ? <ReservationsPage /> : <HotelsPage />} />
              <Route path="hotels" element={<HotelsPage />} />
              <Route path="reservations" element={<ReservationsPage />} />
              <Route path="server-error" element={<ServerError />} />
              <Route path="404" element={<Error404 />} />
            </Route>
            <Route path="/logout" element={<LogoutFallback />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </>
  );
};
