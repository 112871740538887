import React, { useState, useEffect } from 'react';
import { Table, Modal, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IndexedHotel, Address } from '@app/domain/hotel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { deleteHotel } from '@app/store/slices/hotels';
import HotelForm from '@app/components/forms/HotelForm';
import { hash } from '@app/utils/utils';

const HotelTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const hotels = useAppSelector((state) => state.hotels.model);
  const loading = useAppSelector((state) => state.hotels.isLoading);
  const [selected, setSelected] = useState<IndexedHotel | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (loading && isModalVisible) setIsModalVisible(false);
  }, [isModalVisible, loading]);

  useEffect(() => setIsModalVisible(!!selected), [selected]);

  const iconStyle = {
    fontSize: '18px',
    marginRight: '8px',
    cursor: 'pointer',
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Owner', dataIndex: 'email', key: 'email' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address?: Address) => (address ? `${address.name}, ${address.city}, ${address.country}` : ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: IndexedHotel) => (
        <>
          <Tooltip title="Edit">
            <EditOutlined style={iconStyle} onClick={() => setSelected(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined style={iconStyle} onClick={() => dispatch(deleteHotel(record.id))} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        key={hash(JSON.stringify(hotels))}
        dataSource={hotels ? [...hotels.data] : []}
        columns={columns}
        loading={loading}
        rowKey="id"
      />
      <Modal
        title={t('form.hotel.update')}
        width={800}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <HotelForm hotel={selected} />
      </Modal>
    </>
  );
};

export default HotelTable;
